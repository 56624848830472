import { z } from 'zod'

import { EnumBuilder } from '@mntn-dev/utility-types'
import { ModelFilterSchema } from '../model-filter-schema.ts'
import { NonEmptyStringSchema } from './property.models.ts'
import {
  OfferIdSchema,
  ProjectIdSchema,
  SurveyIdSchema,
  UserIdSchema,
} from './unique-id.models.ts'

/**
 * SurveyRating
 */
export const SurveyRatingSchema = z.number().int().min(1).max(5)

/**
 * OfferFeedbackSchema
 */
export const OfferFeedbackMaxLength = 4096
export const OfferFeedbackSchema = NonEmptyStringSchema(OfferFeedbackMaxLength)
export type OfferFeedback = z.infer<typeof OfferFeedbackSchema>

/**
 * OfferAcceptedReason
 */
export const [
  OfferAcceptedReasons,
  OfferAcceptedReasonSchema,
  OfferAcceptedReasonEnum,
] = EnumBuilder(
  'great-brand',
  'good-project-for-portfolio',
  'aligns-with-values-and-culture',
  'lucrative-budget',
  'high-confidence-in-doing-an-excellent-job',
  'industry-matches-experience',
  'existing-relationship-with-customer',
  'other'
)

export type OfferAcceptedReason = z.infer<typeof OfferAcceptedReasonSchema>

/**
 * OfferDeclinedReason
 */
export const [
  OfferDeclinedReasons,
  OfferDeclinedReasonSchema,
  OfferDeclinedReasonEnum,
] = EnumBuilder(
  'cannot-make-deadline',
  'insufficient-budget',
  'not-taking-new-projects',
  'unclear-scope',
  'not-interested',
  'other'
)

export type OfferDeclinedReason = z.infer<typeof OfferDeclinedReasonSchema>

/**
 * ProjectCompletedResponsesSchema
 */
export const ProjectCompletedResponsesSchema = z.object({})

/**
 * OfferAcceptedResponsesSchema
 */
export const OfferAcceptedResponsesSchema = z.discriminatedUnion('reason', [
  z.object({
    reason: OfferAcceptedReasonSchema.extract(['other']),
    feedback: OfferFeedbackSchema,
  }),
  z.object({
    reason: OfferAcceptedReasonSchema.exclude(['other']),
    feedback: OfferFeedbackSchema.optional(),
  }),
])

export type OfferAcceptedResponses = z.infer<
  typeof OfferAcceptedResponsesSchema
>

/**
 * OfferDeclinedResponsesSchema
 */
export const OfferDeclinedResponsesSchema = z.discriminatedUnion('reason', [
  z.object({
    reason: OfferDeclinedReasonSchema.extract(['other']),
    feedback: OfferFeedbackSchema,
  }),
  z.object({
    reason: OfferDeclinedReasonSchema.exclude(['other']),
    feedback: OfferFeedbackSchema.optional(),
  }),
])

export type OfferDeclinedResponses = z.infer<
  typeof OfferDeclinedResponsesSchema
>

/**
 * SurveyType
 */
export const [SurveyTypes, SurveyTypeSchema, SurveyTypeEnum] = EnumBuilder(
  'project-completed',
  'offer-accepted',
  'offer-declined'
)
export type SurveyType = z.infer<typeof SurveyTypeSchema>

export const ActiveSurveyTypes = [
  'offer-accepted',
  'offer-declined',
] as const satisfies SurveyType[]

export const OfferSurveyTypes = [
  'offer-accepted',
  'offer-declined',
] as const satisfies SurveyType[]

export type OfferSurveyType = (typeof OfferSurveyTypes)[number]

/**
 * SurveyDetailsSchema
 */
export const SurveyDetailsSchema = z.discriminatedUnion('surveyType', [
  z.object({
    surveyType: SurveyTypeSchema.extract(['project-completed']),
    projectId: ProjectIdSchema,
    responses: ProjectCompletedResponsesSchema,
  }),
  z.object({
    surveyType: SurveyTypeSchema.extract(['offer-accepted']),
    offerId: OfferIdSchema,
    responses: OfferAcceptedResponsesSchema,
  }),
  z.object({
    surveyType: SurveyTypeSchema.extract(['offer-declined']),
    offerId: OfferIdSchema,
    responses: OfferDeclinedResponsesSchema,
  }),
])

/**
 * SurveySubjectIdSchema
 */
export const SurveySubjectIdSchema = z.union([ProjectIdSchema, OfferIdSchema])

export type SurveySubjectId = z.infer<typeof SurveySubjectIdSchema>

/**
 * SurveyDomainSelectModel
 */
export const SurveyDomainSelectModelSchema = z.object({
  surveyId: SurveyIdSchema,
  responderId: UserIdSchema,
  details: SurveyDetailsSchema,
})

export type SurveyDomainSelectModel = z.infer<
  typeof SurveyDomainSelectModelSchema
>

/**
 * SurveyDomainInsertModel
 */
export const SurveyDomainInsertModelSchema =
  SurveyDomainSelectModelSchema.partial({ surveyId: true })

export type SurveyDomainInsertModel = z.infer<
  typeof SurveyDomainInsertModelSchema
>

/**
 * SurveyDomainSelectModelFilters
 */
export const SurveyDomainSelectModelFiltersSchema = z
  .object({
    surveyId: ModelFilterSchema(SurveyIdSchema),
    surveyType: ModelFilterSchema(SurveyTypeSchema),
    responderId: ModelFilterSchema(UserIdSchema),
    subjectId: ModelFilterSchema(SurveySubjectIdSchema),
  })
  .partial()

export type SurveyDomainSelectModelFilters = z.infer<
  typeof SurveyDomainSelectModelFiltersSchema
>
