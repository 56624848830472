export const getVercelEnvironment = () => {
  const {
    VERCEL,
    VERCEL_ENV: environment,
    VERCEL_GIT_COMMIT_REF: branch,
    VERCEL_GIT_COMMIT_SHA: sha,
  } = process.env

  // Ignore coverage of the return because it's not possible to test both branches

  /* c8 ignore next */
  return VERCEL ? { environment, branch, commit: sha?.slice(0, 7) } : undefined
}
