import type { OverrideProperties } from 'type-fest'
import { z } from 'zod'

import { EnumBuilder } from '@mntn-dev/utility-types'
import { ModelFilterSchema } from '../model-filter-schema.ts'
import {
  DateSchema,
  NonEmptyStringSchema,
  UrlSchema,
} from './property.models.ts'
import { AgreementIdSchema } from './unique-id.models.ts'

export const [AgreementTypes, AgreementTypeSchema, AgreementTypeEnum] =
  EnumBuilder(
    'project-statement-of-work', // SOW terms to be accepted by Brand
    'project-greenlight-agreement', // GLA terms to be accepted by Maker
    'maker-project-payment-terms' // Payment terms to be accepted by Maker
  )
export type AgreementType = z.infer<typeof AgreementTypeSchema>

export const AgreementVersionSchema = DateSchema

/**
 * AgreementDomainSelectModel
 */
export const AgreementDomainSelectModelSchema = z.object({
  agreementId: AgreementIdSchema,
  type: AgreementTypeSchema,
  version: AgreementVersionSchema,
  title: NonEmptyStringSchema(),
  link: UrlSchema,
})

export type AgreementDomainSelectModel<
  Type extends AgreementType = AgreementType,
> = OverrideProperties<
  z.infer<typeof AgreementDomainSelectModelSchema>,
  { type: Type }
>

/**
 * AgreementDomainSelectModelFilters
 */
export const AgreementDomainSelectModelFiltersSchema = z
  .object({
    agreementId: ModelFilterSchema(AgreementIdSchema),
    type: ModelFilterSchema(AgreementTypeSchema),
  })
  .partial()

export type AgreementDomainSelectModelFilters = z.infer<
  typeof AgreementDomainSelectModelFiltersSchema
>

export const ProjectAgreementSchema = AgreementDomainSelectModelSchema.pick({
  agreementId: true,
  version: true,
  type: true,
})

export type ProjectAgreement = z.infer<typeof ProjectAgreementSchema>

export const ProjectStatementOfWorkAgreementSchema =
  ProjectAgreementSchema.extend({
    type: AgreementTypeSchema.extract(['project-statement-of-work']),
  })

export type ProjectStatementOfWorkAgreement = z.infer<
  typeof ProjectStatementOfWorkAgreementSchema
>
