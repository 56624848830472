import type { z } from 'zod'

import { EnumBuilder } from '@mntn-dev/utility-types'

export const [ActivityTypes, ActivityTypeSchema, ActivityTypeEnum] =
  EnumBuilder(
    'agreement_terms_accepted',
    'comment_created',
    'post_production_completed',
    'pre_production_brand_feedback_submitted',
    'pre_production_completed',
    'pre_production_maker_proposal_submitted',
    'project_completed',
    'project_created',
    'project_custom_price_finalized',
    'project_file_added',
    'project_in_production',
    'project_matched',
    'project_not_matched',
    'project_offer_expired',
    'project_offer_sent',
    'project_service_added',
    'project_submitted',
    'review_status_changed',
    'service_note_added'
  )

export type ActivityType = z.infer<typeof ActivityTypeSchema>
